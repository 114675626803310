// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0,0,0;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  /** primary **/
  --ion-color-primary: #146b7b !important; /* Base Teal color */
  --ion-color-primary-rgb: 20, 107, 123 !important; /* RGB equivalent of #146b7b */
  --ion-color-primary-contrast: #ffffff !important; /* A strong contrast, typically white */
  --ion-color-primary-contrast-rgb: 255, 255, 255 !important; /* RGB equivalent of white */
  --ion-color-primary-shade: #105e6b !important; /* A slightly darker shade of the base teal */
  --ion-color-primary-tint: #2b7d8b !important; /* A slightly lighter tint of the base teal */

  /** secondary **/
  --ion-color-secondary: #2fadb5 !important; /* Base Lighter Teal color */
  --ion-color-secondary-rgb: 47, 173, 181 !important; /* RGB equivalent of #2fadb5 */
  --ion-color-secondary-contrast: #ffffff !important; /* A strong contrast, typically white for readability */
  --ion-color-secondary-contrast-rgb: 255, 255, 255 !important; /* RGB equivalent of white */
  --ion-color-secondary-shade: #289c9f !important; /* A slightly darker shade of the base lighter teal */
  --ion-color-secondary-tint: #54b9bf !important; /* A slightly lighter tint of the base lighter teal */

  /** tertiary **/
  --ion-color-tertiary: #FF6B6B !important; /* Base Coral color */
  --ion-color-tertiary-rgb: 255, 107, 107 !important; /* RGB equivalent of #FF6B6B */
  --ion-color-tertiary-contrast: #ffffff !important; /* Strong contrast for legibility, typically white */
  --ion-color-tertiary-contrast-rgb: 255, 255, 255 !important; /* RGB equivalent of white */
  --ion-color-tertiary-shade: #e65a5a !important; /* A slightly darker shade of the base coral */
  --ion-color-tertiary-tint: #ff7878 !important; /* A slightly lighter tint of the base coral */

  /** custom css **/
  .ion-color-transparent {
    --ion-color-base: #224068;
    --ion-color-contrast: #56b4d3;
    .item .item-content {
      background-color: transparent !important;
    }
  }

  /* custom */
  --ion-color-nice-gray: #484848;
  --gb-color-light: #E7E7E7;
  --gb-border-color: #dddddd;
  --ion-border-color: #dddddd;

  --ion-padding: 16px;
  --ion-margin: 16px;

  --medium-screen-size: 700px;
  --half-ion-padding: calc(var(--ion-padding) / 2);
  --half-ion-margin: calc(var(--ion-margin) / 2);

  --gb-border-radius: 6px;
  --max-page-width: 1110px;

  --xsmall-text-size: 10px;
  --small-text-size: 12px;
  --medium-text-size: 14px;
  --large-text-size: 20px;
  --xlarge-text-size: 22px;
}

.ion-color-nice-gray {
  --ion-color-base: var(--ion-color-nice-gray) !important;
}
