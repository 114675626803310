body {
    background-color: var(--ion-background-color);
}

//ion-content {
//    transition: all 300ms;
//}

.gb-transparent {
    --background: transaprent !important;
    --ion-item-background: transparent !important;
}

.gb-border-bottom {
    border-bottom: 1px solid var(--ion-border-color);
}

.gb-home-page-refresher .refresher-pulling-icon,
.gb-home-page-refresher .refresher-refreshing-icon {
    color: var(--ion-color-primary) !important;
    --color: var(--ion-color-primary) !important;
}

.multi-line-labels-with-padding {
    display: flex;
    flex-direction: column;
    padding: 16px 0;

    .first-line {
        font-size: 14px;
    }

    .second-line {
        font-size: 12px;
        color: var(--ion-color-medium);
    }
}

.multi-line-labels {
    display: flex;
    flex-direction: column;

    .first-line {
        font-size: 14px;
    }

    .second-line {
        font-size: 12px;
        color: var(--ion-color-medium);
    }
}

.no-scrollbar {
    scrollbar-width: none;
}

::-webkit-scrollbar {
    display: none;
}

.help-action-sheet {
    .action-sheet-group:last-child {
        padding-bottom: 30px;
    }
}

.link-without-underline {
    text-decoration: none;
}

.input-with-border {
    --padding-bottom: 10px;
    --padding-top: 10px;
    --padding-start: 15px;
    --padding-end: 15px;
    border: 1px solid var(--ion-color-light);
    border-radius: 10px;
    font-size: 14px;
}

.input-with-note {
    .input-with-border {
        margin-bottom: 10px;
    }

    ion-label {
        font-size: 12px;
        color: var(--ion-color-medium);
    }
}

.time-range {
    //border: 1px solid var(--ion-color-light);
    //border-radius: 10px;
    //padding: 10px;

    &.danger {
        border-color: var(--ion-color-danger);
    }

    //ion-label {
    //    font-size: 12px;
    //    color: var(--ion-color-medium);
    //}
}

.scrollable-containers {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    &.image-sliders {
        .box {
            flex-direction: column;
            padding: 0;
            align-items: center;

            ion-icon {
                margin: 0 auto;
                padding: var(--ion-padding, 16px) 0;
                font-size: 25px;
            }

            .image {
                width: 70vw;
                max-width: 200px;
                border-radius: 20px;
                box-shadow: rgb(56 58 62 / 15%) 0 2px 8px;
            }
        }
    }

    &.card-sliders {
        .box {
            box-shadow: rgb(56 58 62 / 15%) 0 2px 8px;
            border: 1px solid var(--ion-border-color);
            flex-direction: row;
            width: 80vh;

            @media screen and (min-width: 768px) {
                width: 40vh;
            }

            .left {
                display: flex;
                flex-direction: column;
                flex-grow: 2;
            }

            .right {
                flex-grow: 1;
                margin-left: var(--ion-margin, 16px);
                align-items: center;
                display: flex;
                flex-direction: column;

                &.image-only {
                    width: 80px;
                }
            }

            .title {
                padding: 5px;
                font-size: 12px;
            }

            .subtitle {
                padding: 5px;
                font-size: 10px;
                color: var(--ion-color-medium);
            }

            ion-button {
                font-size: 12px;
            }

            .image {
                height: 80px;
                border-radius: 20px;
            }

            ion-icon {
                font-size: 25px;
            }
        }
    }

    .box {
        margin: calc(var(--ion-margin, 16px) / 2) 0;
        border-radius: 15px;
        transition: 0.4s;
        padding: 10px 15px;
        display: flex;
        margin-right: var(--ion-margin, 16px);
        width: 100%;

        &.full-width {
            margin-right: 0;
        }
    }
}

.full-page-loading-spinner {
    align-self: center;
    transform: scale(3);
    margin: 0 auto;
    display: flex;
}

@media screen and (min-width: 700px) {
    .multi-column-boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &.space-between {
            justify-content: space-between !important;
        }

        &.screen-centered {
            margin: 0 auto;
            max-width: var(--max-page-width);
        }

        &.left-container-bigger {
            .column-box:first-child {
                flex-grow: 2;
                max-width: 60%;
            }

            .column-box:last-child {
                flex-grow: 1;
                max-width: 40%;
            }
        }

        &.ion-margin-top {
            margin-top: var(--ion-margin) !important;
        }

        &.right-container-bigger {
            .column-box:first-child {
                flex-grow: 1;
                max-width: 40%;
            }

            .column-box:last-child {
                flex-grow: 2;
                max-width: 60%;
            }
        }

        &.equal-width {
            .column-box {
                flex-grow: 1;
            }
        }

        &.reverse-direction {
            flex-direction: row-reverse;
        }

        .column-box {
            border-radius: var(--gb-border-radius);
            padding: var(--ion-padding);

            &:first-child {
                margin-right: calc(var(--ion-margin) * 2);
            }

            &.with-shadow {
                box-shadow: rgb(56 58 62 / 15%) 0 2px 8px;
            }

            &.no-padding {
                padding: 0;
            }
        }
    }
}

//.logo {
//    display: flex;
//    align-items: center;
//    padding: var(--ion-padding, 16px);
//    cursor: pointer;
//
//    ion-img {
//        //width: 4vw;
//        width: 50px;
//    }
//
//    ion-label {
//        padding-left: var(--ion-padding, 16px);
//        font-size: 20px;
//    }
//}

.multi-line-labels-with-padding {
    .group {
        padding: var(--half-ion-padding) 0;
    }
}

.reservation-item-detail .wash-type-item {
    margin: 0 var(--ion-margin) 0 0 !important;
}

.center-loading-spinner {
    align-self: center;
    transform: scale(3);
    margin: 50px auto;
    display: flex;
}

ion-skeleton-text.xlarge-width {
    width: 100%;
    height: 50px;
    border-radius: var(--gb-border-radius);
}

ion-skeleton-text.large-width {
    width: 80%;
    height: 50px;
    border-radius: var(--gb-border-radius);
}

ion-skeleton-text.medium-width {
    width: 50%;
    height: 50px;
    border-radius: var(--gb-border-radius);
}

ion-skeleton-text.small-width {
    width: 30%;
    height: 50px;
    border-radius: var(--gb-border-radius);
}

.card {
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: var(--ion-padding);
    margin: var(--half-ion-margin);
    flex-grow: 1;
    border: 1px solid var(--ion-border-color);

    &.no-margin {
        margin: 0;
    }

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 16px;
        font-weight: 600;
        margin-top: var(--ion-margin);
        margin-bottom: var(--half-ion-margin);
    }

    h4 {
        font-size: 14px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: var(--half-ion-margin);
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 0;
    }

    .cta-buttons {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    .cta-button {
        display: inline-block;
        background-color: #007bff;
        color: #FFFFFF;
        border-radius: 4px;
        padding: 8px 12px;
        text-decoration: none;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        transition: background-color 0.2s ease;
    }

    .cta-button:hover {
        background-color: #0062cc;
    }

    .collapse-button {
        background-color: transparent;
        border: none;
        color: #007bff;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    .collapse-button:hover {
        text-decoration: underline;
    }
}

.availability-date-picker {
    --background: var(--ion-background-color);
}

.gb-center-popover {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);

    &::part(content) {
        display: flex;
        width: 70%;
        height: 70%;
    }

    &::part(arrow) {
        display: none;
    }

    &.no-padding {
        &::part(content) {
            padding: 0;
        }
    }

    &.fixed-height {
        &::part(content) {
            height: 70%;
        }
    }

    &.transparent-background {
        &::part(content) {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }
}

.large-center-loading-spinner {
    align-self: center;
    transform: scale(3);
    margin: 50vh auto;
    display: flex;
}

.input-item {
    font-size: 14px;
    margin: var(--half-ion-margin) 0;

    ion-select {
        white-space: break-spaces;
    }

    &.read-only {
        pointer-events: none;
    }

    ion-note {
        padding-bottom: var(--half-ion-padding);
        white-space: break-spaces;
        font-size: 11px;
    }

    .helper-text {
        font-size: 11px;
    }
}

.gb-helper-text {
    padding-bottom: var(--half-ion-padding);
    white-space: break-spaces;
    margin-left: var(--ion-margin);

    &.no-margin {
        margin: 0;
    }

    ion-note {
        font-size: 11px;
        color: #737373;
    }

    ion-label {
        font-size: 11px;
    }
}

.gb-xsmall-text-size {
    font-size: var(--xsmall-text-size) !important;
}

.gb-small-text-size {
    font-size: var(--small-text-size) !important;
}

.gb-medium-text-size {
    font-size: var(--medium-text-size) !important;
}

.gb-large-text-size {
    font-size: var(--large-text-size) !important;
}

.gb-xlarge-text-size {
    font-size: var(--xlarge-text-size) !important;
}

.medium-icon {
    font-size: 25px;
}

.icon-selector-popover {
    --min-width: 300px !important;
}

.gb-half-padding {
    --padding: var(--half-ion-padding);
    padding: var(--half-ion-padding);
}

.gb-half-padding-top {
    --padding-top: var(--half-ion-padding);
    padding-top: var(--half-ion-padding);
}

.gb-half-padding-horizontal {
    --padding-start: var(--half-ion-padding);
    --padding-end: var(--half-ion-padding);
    padding-left: var(--half-ion-padding);
    padding-right: var(--half-ion-padding);
}

.gb-half-padding-vertical {
    --padding-top: var(--half-ion-padding);
    --padding-bottom: var(--half-ion-padding);
    padding-top: var(--half-ion-padding);
    padding-bottom: var(--half-ion-padding);
}

.gb-half-padding-bottom {
    --padding-bottom: var(--half-ion-padding);
    padding-bottom: var(--half-ion-padding);
}

.gb-half-padding-start {
    --padding-start: var(--half-ion-padding);
    padding-left: var(--half-ion-padding);
}

.gb-half-padding-end {
    --padding-end: var(--half-ion-padding);
    padding-right: var(--half-ion-padding);
}

.gb-half-margin {
    --margin: var(--half-ion-margin);
    margin: var(--half-ion-margin);
}

.gb-half-margin-horizontal {
    --margin-start: var(--half-ion-margin);
    --margin-end: var(--half-ion-margin);
    margin-left: var(--half-ion-margin);
    margin-right: var(--half-ion-margin);
}

.gb-half-margin-vertical {
    --margin-top: var(--half-ion-margin);
    --margin-bottom: var(--half-ion-margin);
    margin-top: var(--half-ion-margin);
    margin-bottom: var(--half-ion-margin);
}

.gb-half-margin-top {
    --margin-top: var(--half-ion-margin);
    margin-top: var(--half-ion-margin);
}

.gb-half-margin-bottom {
    --margin-bottom: var(--half-ion-margin);
    margin-bottom: var(--half-ion-margin);
}

.gb-half-margin-start {
    --margin-start: var(--half-ion-margin);
    margin-left: var(--half-ion-margin);
}

.gb-half-margin-end {
    --margin-end: var(--half-ion-margin);
    margin-right: var(--half-ion-margin);
}

.list-header-with-icon {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    ion-list-header {
        display: flex;
        align-items: center;
    }
}

.gb-top-toolbar {
    &.transparent {
        --background: transparent;
        box-shadow: none;
    }
}

.outlined-item {
    border: 1px solid var(--ion-color-medium);
    border-radius: 4px;
    padding: var(--half-ion-padding);
}

.gb-page-title {
  font-weight: 800;
  font-size: 1.5em;
}

ion-list.list-with-spacing {

    & > * {
        margin-top: var(--ion-margin);
        --margin-top: var(--ion-margin);
    }

    & > *:first-child {
        margin-top: 0;
        --margin-top: 0;
    }
}

/* App Title Styles */
.app-title {
    font-family: "Capriola", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 40px;
    color: var(--ion-color-primary);

    &.rzypro-color {
        color: #146b7b;
    }
}